<template>
  <div class="overflow-x-auto">
    <div class="my-6 text-end">
      <h3 class="font-bold">{{ route.name }}</h3>
      <span class="text-sm text-gray-500">Aqui você pode observar todos os itens relacionados ao
        {{ route.name.toLowerCase() }} como suas respectivas ações.
      </span>
    </div>
    <div class="flex flex-row mt-5 items-center">
      <div class="w-full p-2">
        <Input type="datalist" id="evento" v-model="eventSelect" label="Evento" placeholder="Selecione um evento"
          :data="eventos" @keyupSearch="changeEvent" />
      </div>
      <div class="flex justify-center mt-3">
        <Button color="primary" :text="`Buscar`" @click="changeEvent" />
      </div>
    </div>

    <div v-if="badges">
      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <CardFinanceiro :number="badges.TotalValorVendido" label="Valor vendido"
            details="Valor total vendido até o momento" color="#9A163D" />
        </div>
        <div class="w-full p-2">
          <CardFinanceiro :number="badges.TotalValorBruto" label="Valor bruto"
            details="80% das vendas feitas a mais de 14 dias" color="#9A163D" />
        </div>
      </div>
      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <CardFinanceiro :number="badges.pagamentosFeitos" label="Pagamentos já feitos"
            details="Pagamentos, adiantamentos ou serviços prestados até o momento" color="#9A163D" />
        </div>
        <div class="w-full p-2">
          <CardFinanceiro :number="badges.valorDisponivelParaReceber" label="Valor disponível para recebimento"
            details="Valor líquido disponível no momento" color="#9A163D" />
        </div>
      </div>
      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <Card :number="badges.Vouchers" label="Vouchers" icon="tikcode" color="#9A163D" />
        </div>
        <div class="w-full p-2">
          <Card :number="badges.IngressosFisicos" label="Ingressos Fisicos vendidos" icon="tikcode" color="#9A163D" />
        </div>
      </div>
    </div>
    <hr class="my-9" />
    <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10 ">
      <div class="w-full">
        <div class="flex-3 w-full text-start">
          <h3 class="font-bold">Informações de Lançamento</h3>
          <span class="text-sm text-gray-500">
            Aqui você pode observar todos os dados relacionados aos Lançamento
            do evento.
          </span>
        </div>
        <div>
          <div class="flex mb-6 justify-end mt-3" v-if="obj == ''">
            <Button color="primary" :text="`Criar novo Lançamento`" @click="erro" />
          </div>
          <div class="flex mb-6 justify-end" v-else>
            <router-link :to="`/financeiro/criar/${obj}`">
              <Button color="primary" :text="`Criar novo Lançamento`" />
            </router-link>
          </div>
        </div>
        <div class="card" v-if="data">
          <DataTable :headers="headers" :data="data" :options="options" color="secondary" :creditoDebito="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import CardFinanceiro from "../../components/Elements/CardFinanceiro.vue";
import Card from "../../components/Elements/Card.vue";
import Input from "../../components/Elements/Input.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute } from "vue-router";
import { toDate } from "../../services/formater";

export default {
  name: "Financeiro",
  components: {
    DataTable,
    Button,
    Input,
    CardFinanceiro,
    Card,
  },
  emits: ["getEvent", "changeEvent"],

  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {

    const alert = inject("alert");

    const download = (dica) => {
      if (dica.comprovante) {
        fetch(`https://santocartao-files.s3.amazonaws.com/meuevento/comprovantes/${dica.comprovante}`)
          .then(resp => resp.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = dica.comprovante;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => alert.open("Error", "Não foi possivel recuperar o comprovante", "danger"));
      }
      else {
        alert.open("Atenção!", "Não há comprovante para esse pagamento", "warning")
      }
    }
    const headers = [
      { key: "Id", value: "id" },
      { key: "Data", value: "data", date: true },
      { key: "Detalhes", value: "detalhes", },
      { key: "Valor", value: "valor", money: true },
      { key: "Pix", value: "pix" },
      {
        key: "Comprovante",
        value: "comprovante",
        title: "Baixar comprovante",
        click: (param) => download(param),
        download: true
      },
      { key: "Ações", value: "" },
    ];
    const route = useRoute();
    const loader = inject("loading");
    const modal = inject("modal");
    const eventos = ref([]);
    const eventoId = ref("");
    const dataVenda = ref([]);
    const obj = ref("");
    const param = ref("");
    const data = ref([]);
    const tipoPag = ref([]);

    const badges = ref({
      TotalValorVendido: "",
      TotalValorBruto: "",
      pagamentosFeitos: "",
      valorDisponivelParaReceber: "",
    });

    const eventSelect = ref("");

    const getTipo = (id) => {
      const [tipo] = tipoPag.value.filter(tipo => tipo.value == id);
      return tipo.label
    }

    const changeEvent = async () => {
      loader.open();

      try {
        const [eventoSelecionado] = eventos.value.filter(
          (evento) => evento.label == eventSelect.value
        );

        obj.value = eventoSelecionado.value;

        localStorage.setItem("event_santo_cartao", obj.value);

        const auxiliar = await GET(`pagamento/${obj.value}/eventos`);

        data.value = auxiliar.map(pagamento => {
          pagamento.detalhes = pagamento.descricao ? pagamento.descricao + " - " + getTipo(pagamento.id_pagamento_tipo) : getTipo(pagamento.id_pagamento_tipo)
          pagamento.creditoDebito = pagamento.idPagamentoTipo.credito_debito === '1' ? 'red' : 'green'
          return pagamento
        })

        badges.value = { ...(await GET(`vendas/${obj.value}/vendas_valores`)) };
        if (data.value.length <= 0)
          alert.open(
            "Atenção!",
            `Não há Pagamentos relacionados a esse evento!`,
            "warning"
          );
      } catch (e) {
        alert.open("Erro", "Selecione um evento", "danger");
      }
      loader.close();
    };
    onMounted(async () => {
      loader.open();

      try {
        const eventosRes = await GET("event");
        const perm = await GET("pagamento_tipo");

        perm.forEach((p) => {
          const debitoCredito = p.type === '1' ? 'D' : 'C'
          const label = p.tipo + ' - ' + debitoCredito

          tipoPag.value.push({ value: p.id, label: label, type: p.type })
        }

        );

        eventosRes.forEach((evento) =>
          eventos.value.push({
            label: evento.nome + " - " + toDate(evento.data),
            value: evento.id,
          })
        );
        if (localStorage.getItem("event_santo_cartao") != 'null') {
          const [eventoSelecionado] = eventos.value.filter(
            (evento) => evento.value == localStorage.getItem("event_santo_cartao")
          );

          eventSelect.value = eventoSelecionado.label;
          obj.value = eventoSelecionado.value;

          const auxiliar = await GET(`pagamento/${obj.value}/eventos`);

          data.value = auxiliar.map(pagamento => {
            pagamento.detalhes = pagamento.descricao ? pagamento.descricao + " - " + getTipo(pagamento.id_pagamento_tipo) : getTipo(pagamento.id_pagamento_tipo)
            pagamento.creditoDebito = pagamento.idPagamentoTipo.credito_debito === '1' ? 'red' : 'green'
            return pagamento
          })



          badges.value = { ...(await GET(`vendas/${obj.value}/vendas_valores`)) };
          if (data.value.length <= 0)
            alert.open(
              "Atenção!",
              `Não há Pagamentos relacionados a esse evento!`,
              "warning"
            );


        }
      } catch (e) {
        console.error(e)
        alert.open("Erro", e, "danger");
      }

      if (route.params.id_evento) {
        param.value = route.params.id_evento;

        const [eventoParam] = eventos.value.filter(
          (evento) => evento.value == param.value
        );

        eventSelect.value = eventoParam.label;

        try {
          const [eventoSelecionado] = eventos.value.filter(
            (evento) => evento.label == eventSelect.value
          );

          obj.value = eventoSelecionado.value;

          const auxiliar = await GET(`pagamento/${obj.value}/eventos`);

          data.value = auxiliar.map(pagamento => {
            pagamento.detalhes = pagamento.descricao ? pagamento.descricao + " - " + getTipo(pagamento.id_pagamento_tipo) : getTipo(pagamento.id_pagamento_tipo)
            pagamento.creditoDebito = pagamento.idPagamentoTipo.credito_debito === '1' ? 'red' : 'green'
            return pagamento
          })

          badges.value = {
            ...(await GET(`vendas/${obj.value}/vendas_valores`)),
          };

          if (data.value.length <= 0)
            alert.open(
              "Atenção!",
              `Não há Pagamentos relacionados a esse evento!`,
              "warning"
            );
        } catch (e) {
          alert.open("Erro", e, "danger");
        }
      }
      loader.close();
    });

    const deleteItem = (dica) => {
      loader.open();
      const deleteFunction = async function () {
        try {
          loader.open();
          await DELETE(`pagamento/${dica.id}`);
          data.value = data.value.filter((item) => item.id != dica.id);
          loader.close();
          alert.open("Sucesso!", `Item deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse item?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };


    const options = [
      { icon: "pen", path: "/financeiro/editar", title: "Editar item" },
      {
        icon: "trash",
        action: (param) => deleteItem(param),
        title: "Excluir item",
      },
    ];

    const erro = () => {
      alert.open("Atenção", `Selecione um evento `, "warning")
    }



    return {
      headers,
      data,
      options,
      route,
      eventos,
      eventoId,
      badges,
      changeEvent,
      eventSelect,
      dataVenda,
      obj,
      erro,
      download,
      tipoPag
    };
  },
};
</script>

<style>
.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>